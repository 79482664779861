<template>
  <div class="container">
    <Header></Header>

    <div class="ny-main">
      <h3 class="ny-title text-center">OUR PROJECTS</h3>

      <div class="ny-con">
        <div class="pro-text">
          <p>STARMAX has partners in more than 200 countries and regions worldwide, including North America, Europe, Asia, Central America, the Middle East, and Africa.</p>
          <p>For 18 years, STARMAX has provided a full range of decoration solutions to 1,649 hotel owners worldwide, as well as bedding, bath towels, tableware, furniture, personal toiletries, and other related hotel supplies.</p>
          <p>Years of experience in the hospitality industry have enabled STARMAX to provide a quality product to all guests and to serve them well throughout the process.</p>
        </div>
        <ul class="pro-list flex-images" id="demo1">
          <li class="item" v-for="(item,index) in list" :key="index" data-aos="zoom-in-up" data-aos-delay="10" data-aos-duration="500" :data-w="item.w" :data-h="item.h">
              <router-link :to="{name:'project-detail',query:{'id':item.content_id}}"><img class="project_img" :src="item.thumb_url" alt="">
              <div class="pro-list-tit">
                <span>{{item.name}}</span>
              </div></router-link>
          </li>

          
        </ul>
      </div>
    </div>
    <!-- main end  -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import "@/assets/js/jquery.flex-images.js";
import { list } from '@/api/project'
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
	  list: [],
    };
  },

  created() {
    this.projectlist()
	
  },

  mounted() {
     this.$AOS.init({

        offset: 200,  
        duration: 600,  
        easing: 'ease-in-sine',  
        delay: 100

      });
	    $('#demo1').flexImages({rowHeight: 300});
  },

  methods: {
    clickLink() {
      this.$router.push({ path: "/project-detail" });
    },
    // 列表
    projectlist() {
      this.loading = true
        list().then(res => {
          this.list = res.data.data.list
          this.count = res.data.count
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
    },   
  
  },
};
</script>

<style scoped>

.ny-main {
  overflow: hidden;
  padding: 50px 8.3vw;
}

.ny-title {
  color: #0a3c5b;
  line-height: 1;
  font-size: 4.1667vw;
  font-family: DINCound;
  font-weight: normal;
}

.ny-con {
  width: 100%;
  margin-top: 35px;
}

.pro-text p {
  line-height: 36px;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
  text-indent: 2em;
}

.pro-list{
  width: 91%;
  margin:auto;
  overflow: hidden;
}
.pro-list li{
  float: left;
  /* width: 50%; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5px;
  position: relative;
  margin: 4px;
  cursor: pointer;
  overflow: hidden;
}
.project_img{ width:350px;}
.pro-list li /deep/ img{
  /*width: 100%;*/
  width:350px;
  transition: all 0.5s;
}

.pro-list li:hover /deep/ img{
  transform: scale(1.2);
}

.pro-list-tit{
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 3;
  text-align: center;
  background-image: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 60%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.9) 100%);
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 60%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.9) 100%);
}

.pro-list-tit span{
  bottom: 10px;
  left: 0;
  display: block;
  padding: 15px;
  overflow: hidden;
  min-height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  z-index: 4;
  font-size: 24px;
  width: 100%;
  box-sizing: border-box;
}


</style>